<template>
  <!-- 扫码登录 -->
  <div class="bodyContent">
    <div class="login_box">
      <div class="login_panel">
        <router-link :to="{ name: 'login'}">
          <div class="login_close"></div>
        </router-link>
      </div>
      <div class="qrcode">
        <div id="wx_reg" class="img"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {getQrCode} from '@/network/user/login'
import  config from '@/config/modeUrl'
	export default {
    mounted(){
        console.log('config信息', config)
        WwLogin({
        "id" : "wx_reg",  
        "appid" : config.WX_AppId,
        "agentid" : config.WX_AgentId,
        // "redirect_uri" :"http://192.168.15.67:8081/author/user/userAuth/ewmLogin",
        "redirect_uri" :encodeURIComponent(config.WX_QRLoginUrl),
      })
    },
    methods:{
      getData(){
       alert('aaa')
      }
    },
  }

</script>

<style lang="scss" scoped>
  .bodyContent{
    padding: 0 20px 90px;
  }
  /*登录框*/
  .login_box {
    /* 居中 */
    left: 0;
    right: 0;
    margin: auto;

    width: 400px;
    height: 540px;
    // height: 60%;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 2px 10px #999;

    .login_panel {
      width: 100%;
      height: 16%;

      .login_close {
        width: 64px;
        height: 64px;
        background: url(~@/assets/img/qrcode.png) no-repeat right top;
        background-size: 100% 100%;
        border-top-right-radius: 5px;
        cursor: pointer;
        z-index: 99;
        float: right;
      }
    }
    .qrcode{
      width: 100%;
      height: 84%;
    }
  }



  /*二维码*/
  .qrcode {
    position: relative;
    text-align: center;
  }


  /* 二维码结果 */
  .qrcode .result {
    display: block;
    width: 240px;
    height: 240px;
    margin: 70px auto 25px;
  }

</style>
